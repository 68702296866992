<script>
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import ClientNavBar from "../miniComponents/ClientNavBar.svelte";
  import {getStrykinAuth} from "../utils/utils.js";
  //  const id = window.location.pathname.split("/").pop()
  const backendUrl = "https://api2.strykin.com";

  let listOfUsers = [];

  fetch(backendUrl + "/private/users", {
    headers: {
      Authorization:  getStrykinAuth(),
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      return response.json();
    })
    .then(({ users, user }) => {
      if (user) {
        listOfUsers = [user];
        $form.UserId = user.id;
      } else {
        listOfUsers = users;
      }
    })
    .catch(err => {
      console.log("Maybe you are not logged in err:", err);
    });

  const { form, errors, state, handleChange, handleSubmit } = createForm({
    initialValues: {
      name: "", //.required(),
      notes: "",
      GroupMembers: [
        {
          userId: "",
          role: "",
        }
      ]
    },
    validationSchema: yup.object().shape({

      name: yup.string(), //.required(),
      description: yup.string(),
      GroupMembers: yup.array().of(
        yup.object().shape({
          userId: yup.string(),
          role: yup.string().nullable(),
        })
      )
    }),
    onSubmit: values => {
      values.images = [values.image];

      values.GroupMembers.forEach((element, i) => {
        values.GroupMembers[i].livePrice = element.UserId;
        values.GroupMembers[i].role = element.role;
      });

      values.groupMembers = values.GroupMembers;

      console.log("VALUES", values);

      fetch(backendUrl + "/private/sellerGroup", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization:  getStrykinAuth()
        },
        body: JSON.stringify(values)
      }).then(() => {
        window.location.href = "/sellergroup";
      });
    }
  });

  const add = () => {
    $form.GroupMembers = $form.GroupMembers.concat({
        userId: "",
        role: "",
    });
    $errors.GroupMembers = $errors.GroupMembers.concat({
        userId: "",
        role: "",
    });
  };

  const remove = i => () => {
    $form.GroupMembers = $form.GroupMembers.filter((u, j) => j !== i);
    $errors.GroupMembers = $errors.GroupMembers.filter((u, j) => j !== i);
  };

  // fetch( backendUrl + '/private/product/' + id , {
  //     method: 'GET', // or 'PUT'
  //     headers: {
  //        'Content-Type': 'application/json',
  //         'Authorization':  'Bearer '+ sessionStorage.getItem('jwt'),
  //         },
  //   }).then(response => response.json())
  //   .then(body =>{
  //     $form = body.parentListing;
  //     $form.image = body.parentListing.images[0];
  //     $form.suplier = body.parentListing.suplier || ''

  //     $form.GroupMembers = body.parentListing.GroupMembers;
  //     body.parentListing.GroupMembers.forEach((el, i)=>{
  //       body.parentListing.GroupMembers[i].rrp = body.parentListing.GroupMembers[i].rrp || ''
  //       body.parentListing.GroupMembers[i].localShippingCost = body.parentListing.GroupMembers[i].localShippingCost || ''
  //       body.parentListing.GroupMembers[i].internationalShippingCost = body.parentListing.GroupMembers[i].internationalShippingCost || ''
  //       body.parentListing.GroupMembers[i].europeanShippingCost = body.parentListing.GroupMembers[i].europeanShippingCost || ''

  //        $errors.GroupMembers = $errors.GroupMembers.concat({
  //             childVariable:'',
  //             livePrice:'',
  //             rrp:'',
  //             stock:'',
  //             localShippingCost:'',
  //             internationalShippingCost:'',
  //             europeanShippingCost:''
  //         });
  //     })

  //   })
</script>

<style>
  .error {
    display: block;
    color: red;
  }
  .form-group {
    display: flex;
    align-items: baseline;
  }
  .button-group {
    display: flex;
  }
  button ~ button {
    margin-left: 15px;
  }

  input.shipping,
  input.stock,
  input.rrp,
  input.live-price {
    width: 90px;
  }

  .form-group:first-of-type label {
    font-size: 17px;
  }

  .form-group label {
    display: inline-block;
    font-size: 0px;
  }

  .form-group > div {
    width: min-content;
  }

  form > label {
    font-size: 17px;
    /* min-width: 19px; */
    display: block;
  }

  form {
    font-family: sans-serif;
  }
  input,
  textarea {
    padding: 3px 4px;
  }

  textarea {
    width: 350px;
    height: 80px;
  }

  textarea#description {
    width: 300px;
    height: 118px;
  }
  .submit {
    display: block;
    margin: 22px;
    padding: 9px 19px;
    font-size: 18px;
    background: #37d2a7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
  }
  input#name {
    width: 300px;
  }
  main {
    margin: 8px;
  }

  .note {
    display: inline-block;
    border: solid 0.5px #b3b3b3;
    padding: 8px 10px;
    margin: 8px 0px;
    background: #0A75CD;
    border-radius: 5px;
    color: white;
  }
  .note .title{
    display: block;
  }


</style>


<ClientNavBar />
<main>
  <form on:submit={handleSubmit}>
    <h1>New Group</h1>


    <br />

    <label for="name">name</label>
    <input
      id="name"
      name="name"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.name} />
    {#if $errors.name}
      <small>{$errors.name}</small>
    {/if}
    <br />

    <label for="description">description: 180 Characters</label>
    <textarea
      id="description"
      name="description"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.description}
      maxlength="190" />
    {#if $errors.description}
      <small>{$errors.description}</small>
    {/if}
    <br />



    <!-- <label for="suplier">suplier</label>
    <input
      id="suplier"
      suplier="suplier"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.suplier}
    />
    {#if $errors.suplier}
      <small>{$errors.suplier}</small>
    {/if}
    <br> -->

    <!-- <label for="livePrice">livePrice</label>
    <input
      id="livePrice"
      livePrice="livePrice"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.livePrice}
    />
    {#if $errors.livePrice}
      <small>{$errors.livePrice}</small>
    {/if}
    <br> -->

    <br/>

    {#each $form.GroupMembers as childProduct, j}
      <div class="form-group">
        <div>
          <label for="userId">userId
          <input
            id="userId"
            userId="userId"
            on:change={handleChange}
            on:blur={handleChange}
            bind:value={$form.GroupMembers[j].userId}
            list="user-list"
            autocomplete="off" />
          <datalist class="user-list" id="user-list">
            {#each listOfUsers as user}
              <option value={user.id}>{user.email}</option>
            {/each}
          </datalist>

          {#if $errors.UserId}
            <small>{$errors.UserId}</small>
          {/if}
          </label>
        </div>

        <div>
          <label>
            Role
            <input
              placeholder="livePrice"
              name={`GroupMembers[${j}].livePrice`}
              on:change={handleChange}
              on:blur={handleChange}
              bind:value={$form.GroupMembers[j].role}
              type="text"
              class="live-price" />
          </label>
          {#if $errors.GroupMembers[j].role}
            <small class="error">{$errors.GroupMembers[j].role}</small>
          {/if}
        </div>

        {#if $form.GroupMembers.length !== 1}
          <button type="button" on:click={remove(j)}>-</button>
        {/if}

      </div>
      {#if j === $form.GroupMembers.length - 1}
        <button type="button" on:click={add}>+</button>
      {/if}
    {/each}

    <button class="submit" type="submit">Make New Group</button>
  </form>
</main>
