<script>
	export let images;
	export let name;
	export let description;
	export let id;
	var backendUrl = 'https://api2.strykin.com';
	
	function handleClick(event) {
		fetch( backendUrl + '/public/activeProduct/'+id, {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({}),
		})

	}
	
</script>

<div class='card'>
	<div class='avatar' style='background: url({images[0]}) no-repeat center center / contain;' ></div>
	<div class='content'>
		<h2>{name}</h2>
		<p>{description}</p>
	</div>
	<div class='actions' >
	  <div data-product-id='{id}' on:click={handleClick} class='make-live btn'><i class="fas fa-arrow-alt-circle-right"></i></div>
	</div>
</div>

<style>
	h2 {
		margin: 0 0 0.5em 0;
		font-size: 16px;
	}

	p {
		margin: 0;
		font-size: 14px;
	}

	.make-live.btn{
		background-color: #fff;
		border-color: #ddd;
		border-width: 0;
		color: #575757;
		cursor: pointer;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding-bottom: .5em;
		padding-left: 1em;
		padding-right: 1em;
		padding-top: .5em;
		text-align: center;
		white-space: nowrap;
		background: #589f5a;
		color: #fbf7f7;
		border-radius: 2px;
		font-size: 1rem;

		position: relative;

		-webkit-transition-duration: 0.4s; /* Safari */
		transition-duration: 0.4s;
		text-decoration: none;
		overflow: hidden;
		cursor: pointer;
	}

	.btn:after {
		content: "";
		background: #90EE90;
		display: block;
		border-radius: 100%;
		position: absolute;
		padding-top: 300%;
		padding-left: 350%;
		margin-left: -20px!important;
		margin-top: -120%;
		opacity: 0;
		transition: all 0.8s
		}

	.btn:active:after {
		padding: 0;
		margin: 0;
		opacity: 1;
		transition: 0s
	}


	.card {
		position: relative;
	    margin: 0.5em 0px;
		padding: 0.5em;
		border: 1px solid #eee;
		border-radius: 4px;
		box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
		min-height: 5em;
		display: flex;
		
	}

	.content {
		flex: 3;
		padding: 0px 5px;
	}

	.card::after {
		clear: both;
		display: block;
	}

	.avatar {
		margin-right:7px;
		width: 132px;
		height: 5em;
		flex:1;
		left: 0.5em;
		top: 0.5em;
		--border-radius: 50%;
		background: no-repeat 50% 50%;
	}

	.actions {
		align-items: center;
		display: flex;
	}
</style>