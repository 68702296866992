<script>
  const backendUrl = "https://api2.strykin.com";
  //import io from 'sockets.io'
  import ClientNavBar from './miniComponents/ClientNavBar.svelte'
  import {getStrykinAuth} from "./utils/utils.js";

  let name = "world";
  let parentListings = [];

  const loadListings = () => {
    fetch(backendUrl + "/private/parentListing", {
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if(response.status === 401){
          return window.location.replace("/login");
        }else{
          return response.json();
        }

      })
      .then(({ parentListing }) => {
        parentListings = parentListing;
      })
  };

  const deleteParent = id => {
    fetch(backendUrl + "/public/parentListing/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        loadListings();
      });
  };

  const deleteChild = id => {
    fetch(backendUrl + "/public/childProduct/" + id, {
      method: "DELETE",
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json()) // or res.json()
      .then(res => {
        loadListings();
      });
  };

  const editChild = id => {
    window.location = "/childProduct/" + id;
  };

  const editParent = id => {
    window.location = "/products_new/" + id;
  };

  const addChild = id => {
    window.location = `/products/${id}/childproduct/new`;
  };

  loadListings();
</script>

<style>
  .btn {
    /* background: grey; */
  margin-top:8px;
  margin-bottom: 8px;
	margin-left:5px;
    padding: 10px 11px;
  }

  .parent-details {
    display: inline-flex;
  }

  .product {
    background: #e0e0e0;
    border: 1px solid black;
    margin: 2px;
  }
  .product:nth-child(even) {
    background: #cecece;
  }

  .child {
    margin: 2px;
    padding: 0px 7px;
    background: white;
    border: 1px solid black;
    display: flex;
    align-items: center;
  }

  .child:nth-child(even) {
    background: #e4e4e4;
  }

  .children {
    padding: 5px;
  }
  .mini-image {
    height: 113px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .product-image {
    height: 55px;
    width: 92px;
    display: inline-block;
  }
  .product-details-group {
    display: inline;
  }
  .button-group {
    display: inline-flex;
  }

  .button-group > div {
    margin: -1px 2px;
    border: 0.5px grey solid;
    padding: 2px;
    --background: #ffffffd6;
    color:white;
    border-radius: 2px;
    cursor: pointer;
    padding: 2px 9px;
  }
  
  .mini-btn{
    background: #279c7c;
  }
  .mini-btn.remove{
    background: #d23737
  }

  .child-name {
    min-width: 35px;
  }
  .parent-details-wrapper {
    display: inline-flex;
  }
  .product-name{
    align-self: center;
    padding:0px 3px;
    font-weight: 500;
    }

</style>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.8.0/css/all.css" />
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous" />
</svelte:head>
<ClientNavBar/>
<div>
  <div class="add-product-form">
    <a href="/products/new_new">
      <div class="btn btn-success">Add new product</div>
    </a>
  </div>
  {#each parentListings as parentListing}
    <div class="product">
      <div class="parent-details-wrapper">
        <div 
          on:click={() => editParent(parentListing.id)}
          class="product-image"
          style=" background: url({parentListing.images[0]}) center / cover;" />
        <div class="product-details-group">
          <div class="parent-details">
            <div class='product-name' >{parentListing.name}</div>
            <div class="button-group">
              <div class='mini-btn edit' on:click={() => editParent(parentListing.id)}>
                <i class="fas fa-pen-square" />
                Edit
              </div>
              <div class='mini-btn remove' on:click={() => deleteParent(parentListing.id)}>
                <i class="fas fa-minus-square" />
                Delete
              </div>
              <!-- <div on:click={() => addChild(parentListing.id)}>
                <i class="fas fa-plus-square" />
              </div> -->
            </div>
          </div>
		  <div>
		    Active:{parentListing.active? '🟢':'🔴'}
		  </div>
        </div>
      </div>
      <!-- <div class="child-container">
        {#each parentListing.ChildProducts as ChildProduct}
          <div class="child">
            <div class="child-name">
              {ChildProduct.childVariable || 'default'}
            </div>
            <div class="button-group">
              <div on:click={() => editChild(ChildProduct.id)}>
                <i class="fas fa-pen-square" />
              </div>
              <div on:click={() => deleteChild(ChildProduct.id)}>
                <i class="fas fa-minus-square" />
              </div>
            </div>
          </div>
        {/each}
      </div> -->
    </div>
  {/each}
  {#if parentListings.length > 10}
  <div class="add-product-form">
    <a href="/products/new_new">
      <div class="btn btn-success">Add new product</div>
    </a>
  </div>
  {/if}
</div>
