<style>

.product-info__product-card{
    width:89%;
    margin-right: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
</style>

<script>



    export let productCard;
    export let productLiveInfo;

    let activeChildIndex = '0'

    const backendUrl = 'https://api2.strykin.com';

    const setActiveChild = (childId) => {
        activeChildIndex = productCard.ChildProducts.findIndex((el)=> el.id === childId )
    }

    const sortChildProductOrder = ()=>{
        const sortByArray = ['A5','A5','A4','A3','A2','A1','UK size 6', 'UK size 8', 'UK size 10', 'UK size 12', 'UK size 14', 'UK size 16','XXS','XS','S','M','L','XL','XXL','3XL']
        if(productCard.ChildProducts && Array.isArray(productCard.ChildProducts)){
            if(productCard.ChildProducts[0].currentLiveStats){
                productCard.ChildProducts = productCard.ChildProducts.sort(function(a, b){
                    if (productCard.ChildProducts[0].currentLiveStats.stock  < 1){
                        return -99
                    }
                    return sortByArray.findIndex((str)=> str.includes(a.childVariable)) - sortByArray.findIndex((str)=> str.includes(b.childVariable))//sortByArray.indexOf(b.childVariable);
                });
            } else{
                productCard.ChildProducts = productCard.ChildProducts.sort(function(a, b){
                    return sortByArray.findIndex((str)=> str.includes(a.childVariable)) - sortByArray.findIndex((str)=> str.includes(b.childVariable))//sortByArray.indexOf(b.childVariable);
                });
            }

        }
    }

    $:productCard,sortChildProductOrder()

    const buyItem  = async(event) => {
        const childId = event.target.getAttribute('data-child-id')
        let orderDetails = null

		const res = await fetch( backendUrl +'/public/activeChildProduct/reserve/' + childId, {
              "headers": {
                "accept": "*/*",
                'Content-Type': 'application/json',
                "cache-control": "no-cache",
                "pragma": "no-cache",
                "sec-fetch-dest": "empty",
                "sec-fetch-mode": "cors",
                "sec-fetch-site": "same-site"
            },
            "method": 'POST',
            "body": JSON.stringify({"quantity": 1}),
		})
		
		const json = await res.json()
        window.location.href = "/buy-item?order=" + json.id + '&child=' + childId;
    }

    const convertNumericToCashVal = (num)=>{
        if( num === null ){
            return 'Not Available'
        }
        return parseInt(num, 10) > 1 ? Dinero({ amount: parseInt(num, 10), currency: 'GBP' }).toFormat('$0,0.00') : 'Free'
        }

</script>

<div class='product-info__product-card active-product'>
       {#if productCard.images} 
        <div style=" background: url({productCard.images[0]}) center / cover;"  class='product-card__gallery product-image'>
            <div class="timer-wrapper" style="display: none;">
                <div id="timer"></div>
            </div>
            
            <div class='gallery__images-wrap '>
                <div class='images-wrap__thumb-nail images-wrap__image-1'></div>
                <div class='images-wrap__thumb-nail images-wrap__image-2'></div>
                <div class='images-wrap__thumb-nail images-wrap__image-3'></div>
            </div>
        </div>
        {/if}
        <div class="product-info">
        <div class="product-header">
            {#if productCard.name}
            <div class="product-name">{@html productCard.name}</div>
            {/if}
            <div class="product-price">
            {#if productCard.ChildProducts[0]}
                {#if productCard.ChildProducts[activeChildIndex].rrp && productCard.ChildProducts[activeChildIndex].rrp > productCard.ChildProducts[activeChildIndex].livePrice}
                <div class='previous-price'>{convertNumericToCashVal(productCard.ChildProducts[activeChildIndex].rrp)}</div>
                {/if}
            <div class='current-price'>{convertNumericToCashVal(productCard.ChildProducts[activeChildIndex].livePrice)}</div>
            {/if}
            </div>
        </div>
        <hr>
        {#if productCard.ChildProducts[0]}
        <div class="site-bio item-description">{productCard.description}</div>
        {/if}
        <div class="checkout-stats hide">
            <i class="fas fa-shopping-basket"></i>
            {#if productCard.ChildProducts[activeChildIndex].currentLiveStats}
            <div class="stats-details">
                <div class="number-in-stock">
                {#if (productCard.ChildProducts[activeChildIndex].currentLiveStats.stock - productCard.ChildProducts[activeChildIndex].currentLiveStats.sold)< 21}
                    <span class="value">{productCard.ChildProducts[activeChildIndex].currentLiveStats.stock - productCard.ChildProducts[activeChildIndex].currentLiveStats.sold}</span> Remaining
                {/if}
                </div>
                <div class="number-in-checkout">
                    <span class="value">{productCard.ChildProducts[activeChildIndex].currentLiveStats.incart}</span> In baskets
                </div>
            </div>
            {/if}
        </div>
        <div class="child-variable-switch">
            {#if productCard.ChildProducts[1] && productLiveInfo[0]}
              <select value={productCard.ChildProducts[activeChildIndex].id} on:change="{(el) =>{setActiveChild(el.target.value)}}">
                {#each productCard.ChildProducts as child}
                  <option value={child.id}>
                    {child.childVariable}
                  </option>
                {/each}
               </select>
            {/if}
        </div>
        {#if productCard.ChildProducts[0] && productCard.ChildProducts[0].currentLiveStats }
        {#if  parseInt(productCard.ChildProducts[activeChildIndex].currentLiveStats.stock, 10) > parseInt(productCard.ChildProducts[activeChildIndex].currentLiveStats.sold, 10)}
        <div class="buy-now-button cta" on:click={buyItem} data-child-id="{productCard.ChildProducts[activeChildIndex].id}">
            BUY NOW
        </div>
        {:else}
        <div class="buy-now-button sold-out cta">
            SOLD OUT
        </div>
        {/if}
        <div class="shipping-details">
            <table class="pricing tg">
            <tbody>
                <tr>
                <td class="tg-0lax">UK shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(productCard.ChildProducts[activeChildIndex].localShippingCost)}</td>
                </tr>
                <tr>
                <td class="tg-0lax">European shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(productCard.ChildProducts[activeChildIndex].europeanShippingCost)}</td>
                </tr>
                <tr>
                <td class="tg-0lax">International shipping</td>
                <td class="tg-0lax">{convertNumericToCashVal(productCard.ChildProducts[activeChildIndex].internationalShippingCost)}</td>
                </tr>
            </tbody>
            </table>
        </div>
        {/if}
    </div>
</div>