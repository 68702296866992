

<script>
  import { createForm } from "svelte-forms-lib";
  import * as yup from "yup";
  import ImageUploader from "../miniComponents/ImageUploader.svelte";
  //import VideoUploader from "../miniComponents/VideoUploader.svelte";
  import ClientNavBar from "../miniComponents/ClientNavBar.svelte";
  import MicroShop from "../microShop_editor.svelte";
  import Banner, { Label, Icon } from '@smui/banner';
  import {getStrykinAuth} from '../utils/utils';
  import ColourSelectionPicker from '../miniComponents/atoms/ColourSelectionPicker.svelte';

  (function(e,g){"object"===typeof exports&&"undefined"!==typeof module?module.exports=g():"function"===typeof define&&define.amd?define(g):(e=e||self,e.currency=g())})(this,function(){function e(b,a){if(!(this instanceof e))return new e(b,a);a=Object.assign({},m,a);var d=Math.pow(10,a.precision);this.intValue=b=g(b,a);this.value=b/d;a.increment=a.increment||1/d;a.groups=a.useVedic?n:p;this.s=a;this.p=d}function g(b,a){var d=2<arguments.length&&void 0!==arguments[2]?arguments[2]:!0;var c=a.decimal;
var h=a.errorOnInvalid,k=a.fromCents,l=Math.pow(10,a.precision),f=b instanceof e;if(f&&k)return b.intValue;if("number"===typeof b||f)c=f?b.value:b;else if("string"===typeof b)h=new RegExp("[^-\\d"+c+"]","g"),c=new RegExp("\\"+c,"g"),c=(c=b.replace(/\((.*)\)/,"-$1").replace(h,"").replace(c,"."))||0;else{if(h)throw Error("Invalid Input");c=0}k||(c=(c*l).toFixed(4));return d?Math.round(c):c}var m={symbol:"$",separator:",",decimal:".",errorOnInvalid:!1,precision:2,pattern:"!#",negativePattern:"-!#",format:function(b,
a){var d=a.pattern,c=a.negativePattern,h=a.symbol,k=a.separator,l=a.decimal;a=a.groups;var f=(""+b).replace(/^-/,"").split("."),q=f[0];f=f[1];return(0<=b.value?d:c).replace("!",h).replace("#",q.replace(a,"$1"+k)+(f?l+f:""))},fromCents:!1},p=/(\d)(?=(\d{3})+\b)/g,n=/(\d)(?=(\d\d)+\d\b)/g;e.prototype={add:function(b){var a=this.s,d=this.p;return e((this.intValue+g(b,a))/(a.fromCents?1:d),a)},subtract:function(b){var a=this.s,d=this.p;return e((this.intValue-g(b,a))/(a.fromCents?1:d),a)},multiply:function(b){var a=
this.s;return e(this.intValue*b/(a.fromCents?1:Math.pow(10,a.precision)),a)},divide:function(b){var a=this.s;return e(this.intValue/g(b,a,!1),a)},distribute:function(b){var a=this.intValue,d=this.p,c=this.s,h=[],k=Math[0<=a?"floor":"ceil"](a/b),l=Math.abs(a-k*b);for(d=c.fromCents?1:d;0!==b;b--){var f=e(k/d,c);0<l--&&(f=f[0<=a?"add":"subtract"](1/d));h.push(f)}return h},dollars:function(){return~~this.value},cents:function(){return~~(this.intValue%this.p)},format:function(b){var a=this.s;return"function"===
typeof b?b(this,a):a.format(this,Object.assign({},a,b))},toString:function(){var b=this.s,a=b.increment;return(Math.round(this.intValue/this.p/a)*a).toFixed(b.precision)},toJSON:function(){return this.value}};return e});

const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return defValue
  if (!obj) return defValue
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  // Find value
  const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj)
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result
}


  //  const id = window.location.pathname.split("/").pop()
  const backendUrl = "https://api2.strykin.com";

  let listOfUsers = [];

  let listOfProducts = [];

  let submitted = false;

  let submissionDelay = false;

  let isCurrentlyActive = false;

  let currentUrl = ''

  let attachedProducts = [{
    "ParentListing":{
      "id":"223d39e9-68f8-44b9-8446-862d7b8278f3",
      "name":"Amazing example gem",
      "description":"A specially curated dazzling green gem",
      "images":["https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/8097254-1628254800972.png"],
      "videos":["https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4"],
      "suplier":"","permRef":null,"active":true,"starItem":false,"rrp":null,"livePrice":null,
      "costPricePerUnit":null,"height":null,"length":null,"width":null,"weight":null,"createdAt":"2020-10-23T18:38:45.257Z","updatedAt":"2020-10-23T18:47:33.044Z","UserId":"91f6c036-3349-4bf4-a950-313460697907","ChildProducts":[{"id":"6301b7ef-f5a0-444f-8675-e7bb3d311f7b","name":"Big Spooky Bundle","childVariable":"Default","description":"A specially curated spooky bundle featuring 2 prints, 3 postcards, 2 stickers, 3 enamel pins and 4 badges","images":["https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/91f6c036-3349-4bf4-a950-313460697907/4299420-1603478260024.png"],"videos":null,"suplier":"","rrp":"7500","livePrice":"3000","costPricePerUnit":null,"stock":"9","reserved":"1","sold":"0","localShippingCost":"150","internationalShippingCost":"500","europeanShippingCost":"400","height":null,"length":null,"width":null,"weight":null,"createdAt":"2020-10-23T18:38:45.275Z","updatedAt":"2020-10-24T00:33:00.025Z","ParentListingId":"223d39e9-68f8-44b9-8446-862d7b8278f3"}]},"ChildProducts":[{"id":"6301b7ef-f5a0-444f-8675-e7bb3d311f7b","name":"Big Spooky Bundle","childVariable":"Default","description":"A specially curated spooky bundle featuring 2 prints, 3 postcards, 2 stickers, 3 enamel pins and 4 badges","images":["https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/91f6c036-3349-4bf4-a950-313460697907/4299420-1603478260024.png"],"videos":null,"suplier":"","rrp":"7500","livePrice":"3000","costPricePerUnit":null,"stock":"9","reserved":"1","sold":"0","localShippingCost":"150","internationalShippingCost":"500","europeanShippingCost":"400","height":null,"length":null,"width":null,"weight":null,"createdAt":"2020-10-23T18:38:45.275Z","updatedAt":"2020-10-24T00:33:00.025Z","ParentListingId":"223d39e9-68f8-44b9-8446-862d7b8278f3"}]}];

  fetch(backendUrl + "/private/users", {
    headers: {
      Authorization: getStrykinAuth(),
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if(response.status === 401){
        return window.location.replace("/login");
      }else{
        return response.json();
      }
    })
    .then(({ users, user }) => {
      if (user) {
        listOfUsers = [user];
        $form.UserId = user.id;
        getProds(user.id).then(()=>{
            getMicroShopDetails(user.id);
        })

      } else {
        listOfUsers = users;
      }
    })
    .catch(err => {
      console.log("Maybe you are not logged in err:", err);
    });



    $: $isSubmitting, resetSubissionDelay()

    const resetSubissionDelay = ()=>{
      submissionDelay = true
      setTimeout(function(){ submissionDelay = false  }, 1000);
    }

  const { form, errors, state, handleChange, handleSubmit,setFieldValue, isSubmitting } = createForm({
    initialValues: {
      UserId: "",
      microshopId:"",
      name: "", //.required(),
      templateName: "",
      templateValues: "",
      mainTitle: 'My Wonderful Superdupa', // titles
      subTitle:"Flash Drop Microshop",
      "facebook":"", //socials
      "etsy":"",
      "personalSite":"",
      "instagram":"",
      "primaryColor":'#130c23',
      "title":"Big Gem's Big Gems", //shopInfo
      "paragraph":"We find the biggest rarest and sparkliest gems out there",
      "coverImageUrl":"https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/3693741-1628257435266.png", //style
      "bioImageUrl":"https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/8813949-1628179899150.png",
      
      "active":false,


      ListedProducts: [
        {
          id: "",
        }
      ]
    },
    validationSchema: yup.object().shape({
      userId: yup.string(),
      name: yup.string().lowercase().required().min(6).matches(/^[a-z0-9_]*$/, { excludeEmptyString: true, message:'must contain only letters, numbers and _ in shop names' }), 
      templateName: yup.string(),
      templateValues: yup.string(),
      microshopId:yup.string(),
      mainTitle: yup.string(), // titles
      subTitle:yup.string(),
      "facebook":yup.string(), //socials
      "etsy":yup.string(),
      "instagram":yup.string(),
      "personalSite":yup.string(),
      "primaryColor":yup.string(),
      "title":yup.string(), //shopInfo
      "paragraph":yup.string(),
      "coverImageUrl":yup.string(),
      "bioImageUrl":yup.string(),

      ListedProducts: yup.array().of(
        yup.object().shape({
          id: yup.string(),
        })
      )
    }),
    onSubmit: values => {


      // $form.UserId = ""
      // $form.microshopId = get(microShop,'id',"");
      // $form.name = get(microShop,'name',""); //.required()
      // $form.templateName = microShop.templateName
      // $form.mainTitle = get(microShop,'templateValues.title.mainTitle',""); // titles 
      // $form.subTitle = get(microShop,'templateValues.title.subTitle',""); // 
      // $form.facebook = get(microShop,'templateValues.socialLinks.facebook',""); //socials 
      // $form.etsy = get(microShop,'templateValues.socialLinks.etsy',"");
      // $form.personalSite = get(microShop,'templateValues.socialLinks.personalSite',"");
      // $form.primaryColor = get(microShop,'templateValues.style.primaryColour',""); //get(microShop,'templateValues.style.bioImageUrl',"");
      // $form.title = get(microShop,'templateValues.shopInfo.title',""); //shopInfo
      // $form.paragraph = get(microShop,'templateValues.shopInfo.paragraph',"");
      // $form.coverImageUrl = get(microShop,'templateValues.style.coverImageUrl',""); //style 
      // $form.bioImageUrl = get(microShop,'templateValues.style.bioImageUrl',"");


      if($form.microshopId){
        values.id = $form.microshopId
      }

      if($form.active){
        isCurrentlyActive = true
      } else {
        isCurrentlyActive = false
      }

      if($form.name){
        currentUrl = $form.name
      }

      const resolveUserName = (url, platform) =>{
        if (!url){
          return
        }
        if(!url.includes('/')){
          if(platform == 'facebook'){
            return 'www.facebook.com/'+ url
          }else if (platform == 'instagram'){
          return  'www.instagram.com/'+ url
          }else if (platform == 'etsy'){
            return  'www.etsy.com/uk/shop/'+ url
          }
        }else{
          return url
        }
      }

      $form.facebook  = resolveUserName($form.facebook,'facebook')
      $form.etsy = resolveUserName($form.etsy,'etsy')
      $form.instagram = resolveUserName(values.instagram,'instagram')




      console.log('YOOOOOO2')


      let templateValues = {
        title:{
          mainTitle:$form.mainTitle,
          subTitle:$form.subTitle,
        },
        socialLinks:{
          facebook:$form.facebook,
          etsy:$form.etsy,
          personalSite:$form.personalSite,
          instagram:$form.instagram,
        },
        style:{
          primaryColour:$form.primaryColor,
          coverImageUrl:$form.coverImageUrl,
          bioImageUrl:$form.bioImageUrl,
        },
        shopInfo:{
          title:$form.title,
          paragraph:$form.paragraph
        }
      }

      values.products = $form.ListedProducts

      values.templateValues = templateValues;

      if(values.microshopId != ""){
        console.log('YOOOOO!')

        return fetch(backendUrl + "/private/microShopController/"+ values.microshopId, {
          method: "PUT", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
            Authorization: getStrykinAuth(),
          },
          body: JSON.stringify(values)
        }).then((rtrn) => {
          submitted = true;
          return rtrn.json()
        // window.location.href = "/products_new";
        }).then((rtrn) => {
        // window.location.href = "/products_new";
        }).catch((err)=>{
          console.log(err)
        })

      }

      fetch(backendUrl + "/private/microShopController", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: getStrykinAuth(),
        },
        body: JSON.stringify(values)
        }).then((rtrn) => {
          submitted = true;
          return rtrn.json()
        // window.location.href = "/products_new";
        }).then((rtrn) => {
        $form.microshopId = get(rtrn,'id',"");
        submitted = true;
       // window.location.href = "/products_new";
      }).catch((err)=>{
          console.log('errr',err)
        });




    }
  });

  const add = () => {
    $form.ListedProducts = $form.ListedProducts.concat({
      id: "",
    });
    $errors.ListedProducts = $errors.ListedProducts.concat({
      id: "",
    });
  };

    const remove = i => () => {
      $form.ListedProducts = $form.ListedProducts.filter((u, j) => j !== i);
      $errors.ListedProducts = $errors.ListedProducts.filter((u, j) => j !== i);
      attachedProducts = [];


      $form.ListedProducts.forEach((element,i) => {
          if( element.id){

            let product = listOfProducts.find((el)=>{return el.id == element.id});
            if (product.videos && !product.videos[0]){
                product.videos = ['https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4']
            }



        //debugger;
          attachedProducts[i] = {
            ParentListing: product,
            ChildProducts: product.ChildProducts
          }
        }
      });
    };


    //get check existing microshop details
    const getMicroShopDetails = (id)=>{

      if( id.length < 6){
        return
      }

      fetch( backendUrl + '/private/user/'+id+'/microShopController' , {
      method: 'GET', // or 'PUT'
      headers: {
          'Content-Type': 'application/json',
            'Authorization':  getStrykinAuth(),
            },
      }).then(response => response.json())
      .then(({microShop}) =>{ // map loaded microshop data on to form

      isCurrentlyActive = get(microShop,'active',false);
      currentUrl = get(microShop,'name','');

       
      

      if(microShop && microShop.MicroShop_Products[0]){
        $form.ListedProducts = microShop.MicroShop_Products.map((item)=>{
          return {id:item.ParentListing.id}
        })
        handleProductChange();
      }




      //$form.UserId = get(microShop,'UserId',"");
      $form.microshopId = get(microShop,'id',"");

      if($form.microshopId){
        let roo = get(microShop,'name',"")
        $form.name = roo.toLowerCase().trim(); //.required()
        $form.templateName = get(microShop,'templateName',"")
        $form.mainTitle = get(microShop,'templateValues.title.mainTitle',"").trim(); // titles 
      $form.mainTitle = get(microShop,'templateValues.title.mainTitle',"").trim(); // titles 
        $form.mainTitle = get(microShop,'templateValues.title.mainTitle',"").trim(); // titles 
        $form.subTitle = get(microShop,'templateValues.title.subTitle',"").trim(); // 
      $form.subTitle = get(microShop,'templateValues.title.subTitle',"").trim(); // 
        $form.subTitle = get(microShop,'templateValues.title.subTitle',"").trim(); // 
        $form.facebook = get(microShop,'templateValues.socialLinks.facebook',"").replace('http://', '').replace('https://', '').trim(); //socials 
      $form.facebook = get(microShop,'templateValues.socialLinks.facebook',"").replace('http://', '').replace('https://', '').trim(); //socials 
        $form.facebook = get(microShop,'templateValues.socialLinks.facebook',"").replace('http://', '').replace('https://', '').trim(); //socials 
        $form.etsy = get(microShop,'templateValues.socialLinks.etsy',"").replace('http://', '').replace('https://', '').trim();
        $form.personalSite = get(microShop,'templateValues.socialLinks.personalSite',"").replace('http://', '').replace('https://', '').trim();
        $form.instagram = get(microShop,'templateValues.socialLinks.instagram',"").replace('http://', '').replace('https://', '').trim();
        $form.primaryColor = get(microShop,'templateValues.style.primaryColour',""); //get(microShop,'templateValues.style.bioImageUrl',"");
        $form.title = get(microShop,'templateValues.shopInfo.title',"").trim(); //shopInfo
        $form.paragraph = get(microShop,'templateValues.shopInfo.paragraph',"").trim();
        $form.coverImageUrl = get(microShop,'templateValues.style.coverImageUrl',"").trim(); //style 
      $form.coverImageUrl = get(microShop,'templateValues.style.coverImageUrl',"").trim(); //style 
        $form.coverImageUrl = get(microShop,'templateValues.style.coverImageUrl',"").trim(); //style 
        $form.bioImageUrl = get(microShop,'templateValues.style.bioImageUrl',"").trim();
        $form.active = get(microShop,'active',false);
      }




      })


     


  }




  const getProds = (id)=>{
    return new Promise((resolve) =>{



    if( id.length < 6){
      return
    }

      fetch( backendUrl + '/private/parentListing?userId='+ id , {
      method: 'GET', // or 'PUT'
      headers: {
         'Content-Type': 'application/json',
          'Authorization':  getStrykinAuth(),
          },
    }).then(response => response.json())
    .then(({parentListing}) =>{
      if(parentListing){
        listOfProducts = parentListing;
        resolve()
      }
    })

    })

  }

  const fillProducts = (prodArray)=>{
    prodArray.forEach((element,i) => {
      if( element.id){
        //debugger;
        let product = listOfProducts.find((el)=>{return el.id == element.id});



        attachedProducts[i] = {
          ParentListing: product,
          ChildProducts: product.ChildProducts
        }
      }
    });

    console.log('attachedProducts',attachedProducts);


  }

  const handleUserIdChange = (e)=>{
    //debugger
    if(e && e.target.value){
        //setFieldValue("UserId", e.target.value)
        $form.UserId = e.target.value
        getProds($form.UserId)
          .then(()=>{ getMicroShopDetails($form.UserId); });
        
    }
  }


  const handleProductChange = (e)=>{
    //debugger
    if(e && e.target.value && e.target.attributes['data-product-index']){ 
        //setFieldValue("UserId", e.target.value)

        $form.ListedProducts[e.target.attributes['data-product-index']] = {id:e.target.value};

    }

        attachedProducts = [];

        $form.ListedProducts.forEach((element,i) => {
          if( element.id){

            let product = listOfProducts.find((el)=>{return el.id == element.id});
            if (!product.videos){
                product.videos = ['https://strykin-client-uploads.s3.eu-west-1.amazonaws.com/noID/2256220-1626359111187.mp4']
            }
           // product.videos = ['https://styrkin.s3-eu-west-1.amazonaws.com/public/john_turner/frogo.mp4']


        //debugger;
          attachedProducts[i] = {
            ParentListing: product,
            ChildProducts: product.ChildProducts
          }

        
        }
      });



        //form.UserId = e.target.value
        //getProds($form.UserId);
  
  }

  //$:  $form, $form.UserId, getProds($form.UserId);
  
 // $: $form.ListedProducts, fillProducts($form.ListedProducts);

  
</script>

<style>
  .error {
    display: block;
    color: red;
  }
  .note a{
    font-weight: bold;
    color: white;
  }
  .form-group {
    display: flex;
    align-items: baseline;
  }
  .button-group {
    display: flex;
  }
  button ~ button {
    margin-left: 15px;
  }

  input.shipping,
  input.stock,
  input.rrp,
  input.live-price {
    width: 90px;
  }

  .form-group:first-of-type label {
    font-size: 17px;
  }

  .form-group label {
    display: inline-block;
    font-size: 0px;
  }

  .form-group > div {
    width: min-content;
  }

  form > label {
    font-size: 17px;
    /* min-width: 19px; */
    display: block;
    margin-top: 5px;
  }
  section{
    padding: 7px 12px;
  }
  h1{
    margin-left:12px;
  }

  section.links-section{
    background: #f2f0f0;
    background: #f2f0f0;
    border: solid 2px #cdcdcd75;
    margin: 10px 0px;
  }

  section.products-section{
    background: #f2f0f0;
    background: #f2f0f0;
    border: solid 2px #cdcdcd75;
    margin: 10px 0px;
  }

  form {
    font-family: sans-serif;
  }
  input,
  textarea {
    padding: 3px 4px;
  }

  textarea {
    width: 100%;
    max-width: 350px;
    height: 80px;
  }

  textarea#description {
    width: 300px;
    height: 118px;
  }
  aside{
    flex-grow: 1;
    transform-origin: top right;
    transform: scale(0.9);
    flex-basis: 207px;
  }

  .submit {
    display: block;
    margin: 22px;
    padding: 9px 19px;
    font-size: 18px;
    background: #37d2a7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
  }
  main {
    /* margin: 8px; */
    display: flex;
    flex-wrap: wrap;
  }

  .note {
    display: inline-block;
    border: solid 0.5px #b3b3b3;
    padding: 8px 10px;
    margin: 8px 0px;
    background: #0A75CD;
    border-radius: 5px;
    color: white;
    transform: scale(1);

  }
  .note a {
    color: white;
    font-weight: bold;
  }
  .note .title{
    display: block;
  }
  .user-id-wrap-hide.active{
    display:none;
  }
  .zero-stock{
    background: #fff39f;
  }


  .labeled.input {
    display: flex;
    /* place-items: center; */
    /* align-self: baseline; */
  }

  .labeled .label {
    background: #d7d7d7;
    align-content: center;
    padding: 4px 10px;
    font-size: 15px;
  }

  small {
    background: #ffc77d;
    padding: 0px 4px;
  }

  .products-section button{
    display: block;
    /* margin: 22px;
    padding: 9px 19px; */
    background: #37d2a7;
    color: white;
    /* border-radius: 7px; */
    border: 1px solid grey;
  }
  label {
      display: block;
      margin-top: 6px;
  }

</style>


<ClientNavBar />
<main>
  <form on:submit={handleSubmit}>
    <h1>My Microshop</h1>

    <section>
    <span class='user-id-wrap-hide {listOfUsers[1]?'':'active'}'>
      <label for="userId">userId</label>
      <input
        id="userId"
        userId="userId"
        on:change={handleUserIdChange}
        on:blur={handleChange}
        bind:value={$form.UserId}
        list="user-list"
        autocomplete="off" />
      <datalist class="user-list" id="user-list">
        {#each listOfUsers as user}
          <option value={user.id}>{user.email}</option>
        {/each}
      </datalist>

      {#if $errors.UserId}
        <small>{$errors.UserId}</small>
      {/if}
      <br />
    </span>

    <!-- <aside class='note'>
    <span class='title'> Note: </span>
     Your shop name will be how you link to your shop
     Eg. www.strykin.com/your-shop-name
    </aside> -->


    <label for="name">Shop name (No spaces)</label>
    <div class="ui labeled input">
     <div class="ui label">
        <span>strykin.com/</span>
    </div>
    <input
      id="name"
      name="name"
      on:change={handleChange}
      on:blur={handleChange}
      placeholder="your_shop_name"
      bind:value={$form.name} />

    </div>
    {#if $errors.name}
      <small>{$errors.name}</small>
    {/if}


    <label for="mainTitle">Main Title</label>
    <input
      id="mainTitle"
      name="mainTitle"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.mainTitle} />
    {#if $errors.mainTitle}
      <small>{$errors.mainTitle}</small>
    {/if}
    <br />


    <label for="subTitle">Sub Title</label>
    <input
      id="subTitle"
      name="subTitle"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.subTitle} />
    {#if $errors.subTitle}
      <small>{$errors.subTitle}</small>
    {/if}
    <br />
    </section>
    <section class='links-section'>
      <h2>Links</h2>

      <label for="facebook">Facebook Link (optional)</label>
      <div class="ui labeled input">
        <div class="ui label">
          <span>https://</span>
        </div>
          <input
          id="facebook"
          name="facebook"
          placeholder="facebook.com/theo.er"
          on:change={handleChange}
          on:blur={handleChange}
          bind:value={$form.facebook} />
      </div>

      {#if $errors.facebook}
        <small>{$errors.facebook}</small>
      {/if}

      <label for="etsy">Etsy Link (optional)</label>
      <div class="ui labeled input">
        <div class="ui label">
          <span>https://</span>
        </div>
      <input
        id="etsy"
        name="etsy"
        on:change={handleChange}
        on:blur={handleChange}
        placeholder="etsy.com/uk/shop/HandmadebyTinni"
        bind:value={$form.etsy} />
      </div>
      {#if $errors.etsy}
        <small>{$errors.etsy}</small>
      {/if}

      <label for="instagram">Instagram Link (optional)</label>
      <div class="ui labeled input">
        <div class="ui label">
          <span>http://</span>
        </div>
      <input
        id="instagram"
        name="instagram"
        on:change={handleChange}
        on:blur={handleChange}
        placeholder="instagram.com/thaliaostara"
        bind:value={$form.instagram} />
      </div>
      {#if $errors.instagram}
        <small>{$errors.instagram}</small>
      {/if}

      <label for="personalSite">Personal Site Link (optional)</label>
        <div class="ui labeled input">
        <div class="ui label">
          <span>https://</span>
        </div>
        <input
          id="personalSite"
          name="personalSite"
          on:change={handleChange}
          on:blur={handleChange}
          placeholder="linktr.ee/thisisjonturner"
          bind:value={$form.personalSite} />
        </div>
      {#if $errors.personalSite}
        <small>{$errors.personalSite}</small>
      {/if}

    </section>
    <section>

    <label for="primaryColor">
      Page Primary colour
    </label>
    <ColourSelectionPicker
    bind:activeValue={$form.primaryColor}
    ></ColourSelectionPicker>
    <input
      id="primaryColor"
      name="primaryColor"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.primaryColor} />
      <input type="color" id="favcolor" bind:value={$form.primaryColor} name="favcolor" >
    {#if $errors.primaryColor}
      <small>{$errors.primaryColor}</small>
    {/if}
    <br />

    <label for="bannerimage">
      Banner pattern (800 × 236 px)
      <ImageUploader
        id="coverImageUrl"
        image="bannerimage"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.coverImageUrl}
        aspectRatio={(1240/367)}
        maxSize = {[1440, 1080]}
        userId={$form.UserId} />
        
    </label>
    {#if $errors.bannerimage}
      <small>{$errors.bannerimage}</small>
    {/if}
    <br />

    </section>
    
    <section class='products-section'>
    <h2>Products</h2>
     <label> Products in the shop </label>

    {#each $form.ListedProducts as childProduct, j}
      <div class="form-group">
        <div>

            <input
              name={`ListedProducts[${j}].id`}
              data-product-index={j}
              placeholder="id"
              on:change={handleProductChange}
              on:blur={handleProductChange}
              list="prod-list"
              bind:value={$form.ListedProducts[j].id} />


            <datalist class="prod-list" id="prod-list">
              {#each listOfProducts as product}
                {#if !$form.ListedProducts.find(el => el.id === product.id) }
                  <option value={product.id}>{product.name}</option>
                {/if}
              {/each}
            </datalist>




          
          <!-- {#if $errors.ListedProducts && $errors.ListedProducts[j].id}
            <small class="error">
              {$errors.ListedProducts[j].id}
            </small>
          {/if} -->
        </div>

    

        {#if $form.ListedProducts.length !== 1}
          <button type="button" on:click={remove(j)}>--</button>
        {/if}
        <!-- {#if $form.ListedProducts.length >= 1}
          <button type="button" on:click={editProduct($form.ListedProducts[j].id)}>Edit Product</button>
        {/if} -->

      </div>
      {#if (j === $form.ListedProducts.length - 1) && ($form.ListedProducts.length < 3)}
        <button type="button" on:click={add}>+</button>
      {/if}
    {/each}
    </section>
    <section>

    <h2>Profile card</h2>


    
    <label for="profileimage">
      Profile Image (514 × 600 px)
      <ImageUploader
        id="bioImageUrl"
        image="profileimage"
        on:change={handleChange}
        on:blur={handleChange}
        bind:value={$form.bioImageUrl}
        aspectRatio={(360/420)}
        userId={$form.UserId} />
        
    </label>
    {#if $errors.bioImageUrl}
      <small>{$errors.bioImageUrl}</small>
    {/if}
    <br />



    <label for="title">Title</label>
    <input
      id="title"
      name="title"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.title} />
    {#if $errors.title}
      <small>{$errors.title}</small>
    {/if}
    <br />


    <label for="paragraph">Paragraph</label>
    <textarea
      id="paragraph"
      name="paragraph"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.paragraph} />
    {#if $errors.paragraph}
      <small>{$errors.paragraph}</small>
    {/if}
    <br />

    
    <!-- <label for="suplier">suplier</label>
    <input
      id="suplier"
      suplier="suplier"
      on:change={handleChange}
      on:blur={handleChange}
      bind:value={$form.suplier}
    />
    {#if $errors.suplier}
      <small>{$errors.suplier}</small>
    {/if}
    <br> -->
    </section>
    <section>

      <label for="active">Active (Do you want to switch your shop on?)</label>
      <input
        id="active"
        active="active"

        bind:checked={$form.active}
        type="checkbox"
      />
      {#if $errors.active}
        <small>{$errors.active}</small>
      {/if}
      <br>

      </section>
      <section>


      {#if $isSubmitting || submissionDelay}
        <button class="submit">...Updating</button>
      {:else if submitted}
        <button class="submit" type="submit">Update success, Update again</button>
      {:else}
        <button class="submit" type="submit">Update my shop</button>
      {/if}
      
      {#if  $form.microshopId && isCurrentlyActive}
        <aside class='note'>
        <span class='title'> Note: </span>
          Your micro site is now avalible at: <a href="{'https://strykin.com/'+currentUrl}">{'https://strykin.com/'+currentUrl}</a>
        </aside>
      {/if}
      </section>


    
  </form>
  <aside class='details'>
    <MicroShop shopData={{
    "title":{
        "mainTitle": $form.mainTitle,
        "subTitle": $form.subTitle
    },
    "socialLinks":{
        "facebook": $form.facebook,
        "etsy":  $form.etsy,
        "personalSite":  $form.personalSite,
        "instagram":  $form.instagram
    },
    products:attachedProducts,
     "shopInfo":{
         "title":$form.title,
         "paragraph":$form.paragraph
        },
    "style":{
        "coverImageUrl":$form.coverImageUrl,
        "bioImageUrl":$form.bioImageUrl,
    }
   }} 
     coverImage={$form.coverImageUrl}
     bioImage={$form.bioImageUrl}
     primaryColour={$form.primaryColor}
   />
  </aside>
  
</main>
