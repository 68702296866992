<script>
  const backendUrl = "https://api2.strykin.com";
  export let shopData = {
    "title":{
        "mainTitle":"John Turner X Strykin",
        "subTitle":"Micro Shop, Flash Drop"
    },
    "socialLinks":{
        "facebook":"",
        "etsy":"",
        "personalSite":""
    },
    products:[],    
     "shopInfo":{
         "title":"About Jon",
         "paragraph":"Forged in the fires of a distant moon, abandoned by his creator, and forced to wander a lonely path through the infinite, desolate vacuum of space, Jon draws on these relatable \"life\" experiences to bring you an exciting cabinet of curiosities."
        },
    "style":{
        "coverImageUrl":"https://i.etsystatic.com/isbl/3b75a0/19371102/isbl_1200x300.19371102_p0hpj4tn.jpg?version=0",
        "bioImageUrl":"https://i.etsystatic.com/ipsi/6761b1/1899428135/ipsi_fullxfull.1899428135_sa140ivu.jpg?version=0"
    }
};
  let state404;
  export let coverImage = "https://unsplash.it/800/600?image=11";
  export let bioImage = "https://unsplash.it/800/600?image=3";
  export let primaryColour = '#130c23' 
  let productLiveInfo  = [];
  
  let stockWithLiveOrders = []

  let stockList = []


  import ProductCard from "./miniComponents/MicroshopProductCard.svelte";
  import Loading from "./miniComponents/Loading.svelte";
  import NotFound from "./miniComponents/404.svelte"

  


  var productSocket = io(backendUrl + '/productStream', {
        transports: ['websocket', 'xhr-polling']
  });


  const lightenDarkenColor = (color, amount) => {
  let colorWithoutHash = color.replace("#", "")
  if (colorWithoutHash.length === 3) {
    colorWithoutHash = colorWithoutHash
      .split("")
      .map(c => `${c}${c}`)
      .join("")
  }

  const getColorChannel = substring => {
    let colorChannel = parseInt(substring, 16) + amount
    colorChannel = Math.max(Math.min(255, colorChannel), 0).toString(16)

    if (colorChannel.length < 2) {
      colorChannel = `0${colorChannel}`
    }

    return colorChannel
  }

  const colorChannelRed = getColorChannel(colorWithoutHash.substring(0, 2))
  const colorChannelGreen = getColorChannel(colorWithoutHash.substring(2, 4))
  const colorChannelBlue = getColorChannel(colorWithoutHash.substring(4, 6))

  return `#${colorChannelRed}${colorChannelGreen}${colorChannelBlue}`
}

  let primaryColourL = lightenDarkenColor(primaryColour, -10)
  let primaryColourS = lightenDarkenColor(primaryColour, 10)

$: primaryColour, (()=>{
  primaryColourL = lightenDarkenColor(primaryColour, -10)
  primaryColourS = lightenDarkenColor(primaryColour, 10)
})();

$: primaryColour, console.log('updatedd')


</script>

<style lang="scss">@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  background-color: #f0f0f0; }

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle; }

a {
  text-decoration: underline;
  cursor: pointer; }

h1 {
  font-size: 2em; }

.wrapper {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.background {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.links {
  margin-bottom: 14px; }

.btn {
  background-color: white;
  border: 1px solid #cccccc;
  color: #696969;
  padding: 0.5rem;
  text-transform: uppercase;
  position: absolute;
  bottom: 12px;
  /* margin: 0px 18px; */
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -113px;
  text-align: center;
  border-top: solid 1px #f5ecdb;
  border-left: solid 1.3px #faf0dc;
  border-right: solid 1px #7d7d7d;
  /* background-color: white; */
  border-bottom: solid 1px #7d7d7d;
  color: white;
  font-weight: 700;
  background: #65d893; }

.btn--block {
  display: block;
  --width: 100%; }

.cards {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px; }

.cards__item {
  display: flex;
  padding: 1rem;
  flex-basis: 360px; }

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .card:hover .card__video {
    filter: contrast(100%); }

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  position: relative; }

.card__video {
  filter: contrast(70%);
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91); }

.card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow: hidden;
  position: relative; }
  .card__image::before {
    content: "";
    display: block;
    padding-top: 177.77778%; }

.wrapper {
  margin-bottom: 100px; }

.card__image--flowers {
  background-image: url(https://unsplash.it/800/600?image=82); }

.card__image--river {
  background-image: url(https://unsplash.it/800/600?image=11); }

.card__image--record {
  background-image: url(https://unsplash.it/800/600?image=39); }

.card__image--fence {
  background-image: url(https://unsplash.it/800/600?image=59); }

.card__title-row {
  display: flex;
  justify-content: space-between;
  font-size: 1.25rem;
  color: black; }

.card__title {
  color: #696969;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  flex: 1 1 auto;
  min-height: 39px;
  display: flex;
  align-items: center; }

.card__price {
  align-self: center;
  margin-left: 2px; }

.card__text {
  flex: 1 1 auto;
  line-height: 1.5; }

.card__rrp {
  color: red;
  text-decoration: line-through;
  font-size: 0.875rem; }

.cover-photo {
  width: 100%;
  height: 40vh;
  top: 0px;
  background-image: var(--coverImage);
  background-size: cover;
  background-position: center;
  margin-bottom: -17vh;
  display: flex;
  position: absolute;
  z-index: -1;
  border-bottom: solid 1px #fafad27d;
  height: 400px; }

.card__video {
  position: absolute;
  top: 0px;
  width: 100%; }

.card__top-info {
  position: absolute;
  top: 12px;
  right: 12px;
  background: white;
  --width: 100%;
  background: #ffffffc2;
  color: #1b1b21;
  padding: 5px; }

.title {
  background: #ffffffa6;
  text-align: center;
  /* display: block; */
  border: solid 1px #00000021;
  backdrop-filter: blur(10px);
  margin: 50px auto;
  height: fit-content;
  width: fit-content;
  color: #1b1b21;
  padding: 4px 20px;
  margin-bottom: 60px; }

.card__checkout-stats {
  background: #ffe67a;
  display: flex;
  padding: 4px 12px;
  align-items: center;
  margin-top: 12px;
  border-left: 4px solid #ff24e6;
  color: #484747; }
  .card__checkout-stats i {
    margin-right: 6px;
    margin-right: 10px;
    font-size: 21px; }

.shipping-details {
  color: #484747; }

table.pricing.tg {
  color: #484747;
  width: 100%;
  margin-top: 7px; }

.pricing tr {
  background: #d2d1db; }

.pricing td {
  padding: 4px 7px; }

.shop-info {
  background: #1f1d24;
  color: #474749;
  -webkit-box-shadow: inset 0px 21px 15px -15px #000000a3;
  box-shadow: inset 0px 21px 15px -15px #000000a3; }

.about-image {
  margin: 0px auto;
  background: url("https://unsplash.it/1000/1000?image=80");
  height: 275px;
  width: 100%;
  max-width: 400px;
  background-position: center;
  background-size: cover; }

.about-info {
  height: 500px;
  width: 100%;
  max-width: 700px;
  margin: 25px auto;
  font-size: 25px;
  padding: 0px 9px; }

.shop-info html {
  font-family: "Helvetica Neue", "Arial";
  box-sizing: border-box; }

.shop-info span {
  display: inline-block;
  width: 50px;
  height: 5px;
  background-color: #2d58c4;
  margin-bottom: 30px; }

.shop-info img {
  width: 100%; }

.shop-info h1 {
  font-weight: 600;
  font-size: 36px;
  margin: 0 0 15px; }

.shop-info p {
  font-weight: 400;
  line-height: 24px;
  margin: 0 0 15px 0; }

.shop-info a {
  text-decoration: none;
  text-transform: uppercase;
  color: #2d58c4;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  display: inline-block;
  z-index: 1;
  padding: 5px;
  left: -5px; }

.shop-info a:hover {
  color: #2d58c4; }

.shop-info div.site {
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(210deg, #1b1b21, #474749); }

.shop-info div.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  max-width: 450px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.45);
  flex-basis: 300px; }

.shop-info div.img {
  height: 300px;
  width: 100%;
  background: var(--bioImage);
  background-position: center;
  background-size: cover; }

.shop-info div.text-container {
  margin: 60px 45px 55px; }

.shop-info div.text-container {
  flex-grow: 1;
  min-width: 210px; }

.shop-info div.text-container p {
  white-space: pre-wrap; }

.shop-info .container .img {
  flex-grow: 1; }

@media screen and (min-width: 1024px) {
  .shop-info div.img {
    height: 420px;
    min-width: 360px; }
  .shop-info div.text-container {
    margin: 0 60px;
    max-width: 660px;
    padding: 18px 0px; } }

@media screen and (min-width: 1024px) {
  .shop-info div.container {
    max-width: 790px;
    flex-direction: row;
    width: 100%;
    position: absolute; } }

.shop-info .social-bar {
  display: flex;
  flex-direction: row; }

.shop-info .social {
  font-size: 21px;
  width: 35px;
  height: 35px;
  margin: 0px 7px;
  display: flex;
  border: black solid 1px;
  border-radius: 50%;
  align-items: center;
  justify-content: center; }

.title {
  margin-bottom: 155px; }

.title-wrap {
  display: flex;
  justify-content: center;
  background-image: var(--coverImage);
  background-size: cover;
  background-position: center; }

.wrapper {
  margin-bottom: 100px;
  margin-top: -100px; }

.background {
  background: url(https://styrkin.s3-eu-west-1.amazonaws.com/public/site-assets/abstract-svg-shapes/5d65445f9f59d937e3e33735_shape-2.svg), url(https://styrkin.s3-eu-west-1.amazonaws.com/public/site-assets/abstract-svg-shapes/5d6543cc41869b67f7091632_shape.svg), linear-gradient(-59deg, var(--primaryColour) 17%, var(--primaryColourL));
  background-size: cover, contain, contain, auto;
  background-size: cover;
  background-repeat: no-repeat, repeat; }

.links {
  padding: 1rem;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  justify-content: space-evenly; }

.links a {
  margin: 5px;
  border: solid 1px #666565;
  padding: 5px 13px;
  background: #f1f1f18c;
  color: black;
  text-decoration: none;
  border-radius: 50%;
  position: relative;
  transition-duration: 0;
  transition-property: color background border box-shadow;
  transition-timing-function: ease-in;
  vertical-align: middle;
  text-align: center;
  border-style: solid;
  border-width: 0.125em;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  padding: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 19px; }

</style>

<svelte:head>
  <!-- elements go here -->

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" />
</svelte:head>
<div  style="--primaryColour:{primaryColour}; --primaryColourL:{primaryColourL}; --primaryColourS:{primaryColourS} "  class="background">
{#if shopData}

  <!-- <div class="cover-photo" style="--coverImage:url({coverImage})" > -->
  <div class='title-wrap' style="--coverImage:url({coverImage})" >
  
  
    <div class="title">
    <h1>{shopData.title.mainTitle}</h1>
    <hr />
    <h2>{shopData.title.subTitle}</h2>
    {#if shopData.socialLinks}
    <div class="links">
        {#if shopData.socialLinks.instagram}
          <a href={'http://' + shopData.socialLinks.instagram}><i class="fab fa-instagram"></i></a>
        {/if}
        {#if shopData.socialLinks.facebook}
          <a href={'http://' + shopData.socialLinks.facebook}><i class="fab fa-facebook"></i> </a>
        {/if}
        {#if shopData.socialLinks.etsy}
          <a href={'http://' + shopData.socialLinks.etsy}><i class="fab fa-etsy"></i></a>
        {/if}
        {#if shopData.socialLinks.personalSite}
          <a href={'http://' + shopData.socialLinks.personalSite}><i class="fa fa-link"></i></a>
        {/if}
    </div>
    {/if}
    <!--     <hr> -->

    <!--     <h3>14 Days remaining</h3> -->

  </div>
  
  </div>
  <div class="wrapper">

    <ul class="cards">
    
      <!-- {#if shopData.products && shopData.products[0] && shopData.products[0].ChildProducts} -->
        {#each shopData.products as product}
          <ProductCard {product} />
        {/each}
      <!-- {/if} -->

    </ul>
  </div>
  {#if shopData.shopInfo}
    <div class="shop-info">
      <div class="site">
        <div class="container">
          <div class="img" style="--bioImage:url({bioImage})" />
          <div class="text-container">

            <h1>{shopData.shopInfo.title}</h1>
            <span />
            <p>{shopData.shopInfo.paragraph}</p>
            {#if shopData.socialLinks}
              <div class="social-bar">
                {#if shopData.socialLinks.facebook}
                  <a target="_blank"  href="{'http://'+ shopData.socialLinks.facebook}">
                    <div class="social">
                      <i class="fab fa-facebook-f" />
                    </div>
                  </a>
                {/if}
                {#if shopData.socialLinks.etsy}
                <a target="_blank"  href="{'http://'+ shopData.socialLinks.etsy}">
                  <div class="social">
                    <i class="fab fa-etsy" /> 
                  </div>
                  </a>
                {/if}
                {#if shopData.socialLinks.instagram}
                <a target="_blank"   href="{'http://'+ shopData.socialLinks.instagram}">
                  <div class="social">
                    <i class="fab fa-instagram" />
                  </div>
                  </a>
                {/if}
                {#if shopData.socialLinks.personalSite}
                <a target="_blank"  href="{'http://'+ shopData.socialLinks.personalSite}">
                  <div class="social">
                    <i class="fa fa-link" />
                  </div>
                  </a>
                {/if}
              </div>
            {/if}
          </div>
        </div>

      </div>
    </div>
  {/if}
{:else if state404}
<NotFound></NotFound>
{:else}
  <Loading />
{/if}
</div>