
<style>
    .date{
  font-size:1.7em
}

.event{
  color:#292929;
  background-image: linear-gradient(135deg, #f1e9ff, #fef5ff);
  min-height: 170px;
  margin:4px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);


}
/* hr{
    border-top: 1px solid #FF24E6;
} */

.event .title{
    font-size:2em;
}

@media only screen and (min-width: 600px) {
 .event .title{
    font-size:1.7em;
 }
}

.event .times{
    font-size:1.2em;
    font-weight:600;
  }

.event .description {
    max-width: 430px;
}

.info{
    padding:7px;
}

.image{
  background:red;
  width:150px;
}
.event{
   display: flex;
}

/* .event.even {
  background: #BA00FF;
}

.event.odd {
  background: #7B01CA; 
} */

.remind-me.google {
    background: #3AFECA;
    display: inline-block;
    color: black;
    padding: 3px 14px;
    margin: 2px;
        margin-top: 8px;
    border-radius: 28px;
    cursor: pointer;
}

.remind-me.google:hover {
    background: #3CCBC5;
}



</style>


<script>

import moment from "moment";
import { google } from "calendar-link";
import {getStrykinAuth} from "../utils/utils.js";

const backendUrl = 'https://api2.strykin.com';    
let bookedDates = [];

async function getRandomNumber() {

const response = await fetch(backendUrl + "/public/stream?time=future", {
headers: {
    Authorization: getStrykinAuth(),
    "Content-Type": "application/json"
}
})

 let bookedStreams = await response.json()

 let eventsGroupedByDate = await Object.values(bookedStreams).map((stream)=>{
     let endTime = moment(stream.startTime).add(1, 'hours')
    return {
        title:stream.name,
        start:stream.startTime,
        end: endTime,
        description:stream.description,
        img:stream.image,
        }
    }).map((item)=>{
        item.date = moment(item.start).format('YYYYMMDD');
        return item;
    })
    .filter(event =>{
        return moment().isBefore(event.start);
    })
    .reduce((resultzz, currentValue) => {

        (resultzz[currentValue.date] = resultzz[currentValue.date] || []).push(
            currentValue
        );

        return resultzz;
    },{})

    return await Object.entries(eventsGroupedByDate).sort()
   
 }


    let promise = getRandomNumber()


    
	const handleClick =(event) =>{
        const link = google({
            start:event.start,
            title:event.title,
            description:event.description,
            end:event.end,
            url:'www.strykin.com',
            location:'www.strykin.com'
        });

        window.open(link,'newwindow','width=700,height=500');
	}
    


</script>
<hr>

{#await promise}
	<p>...Loading coming up</p>
{:then eventsGroupedByDate}
	
    {#if eventsGroupedByDate[0] }
        <h1>Coming up</h1>
    {/if}

    <div class='schedule'>
        {#each eventsGroupedByDate as eventDate, i}
            <div>
            <div class='date'>{moment(eventDate[1][0].start).format('dddd Do MMMM')}</div>
            {#each eventDate[1] as event}
                <div class='event {event.type} {i % 2 ? 'odd' : 'even' }'>
                    <div class='image' style='background:url({event.img+'?v='+event.start}); background-size:contain; background-repeat: no-repeat; background-color: white; background-position: center;'></div>
                    <div class='info' >
                    <div class='title'>
                        {event.title}
                    </div>
                    <div class='times'>
                        {moment(event.start).format('h:mma')} - 
                        {
                        event.end ?  moment(event.end).format('h:mma'): moment(event.start).add(1, 'hour').format('h:mma') } (GMT)
                    </div>
                    <hr>
                        <div class='description'>
                            {event.description}
                        </div>
                        <div class='remind-me google' on:click={() => handleClick(event)}>
                        <span class='icon'>+</span> Set Google Reminder
                        </div>
                    </div>
                </div>
            {/each}
            </div>
        {/each}
    
    </div>



{:catch error}
	<p style="color: red">{error.message}</p>
{/await}