<script>
  const backendUrl = "https://api2.strykin.com";
  //import io from 'sockets.io'
  import ClientNavBar from './miniComponents/ClientNavBar.svelte'
  import CompresedProductCard from './miniComponents/CompresedProductCard.svelte'
  import {getStrykinAuth} from "./utils/utils.js";

  // const fetchSomeProducts = (async () => {
	// 	const response = await fetch('https://dog.ceo/api/breeds/image/random')
  //   return await response.json()
  // })()
  

  const fetchSomeProducts = (async () => {
		const response = await fetch(backendUrl + "/private/parentListing?limit=5&order=DESC", {
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })

    if(response.status === 401){
      return window.location.replace("/login");
    }else{
      return await response.json();
    }


  })()
  

  const fetchShopInfo = (async () => {
		const response = await fetch(backendUrl + "/private/microShopController/me", {
      headers: {
        Authorization: getStrykinAuth(),
        "Content-Type": "application/json"
      }
    })

    if(response.status === 401){
      return window.location.replace("/login");
    }else{
      console.log( await response)
      return await response.json();
    }


  })()


  //console.log(testShop())



    // fetch(backendUrl + "/private/parentListing", {
    //   headers: {
    //     Authorization: getStrykinAuth(),
    //     "Content-Type": "application/json"
    //   }
    // })
    //   .then(response => {
    //     if(response.status === 401){
    //       return window.location.replace("/login");
    //     }else{
    //       return response.json();
    //     }

    //   })
    //   .then(({ parentListing }) => {
    //     parentListings = parentListing;
    //   })


</script>

<style lang="scss">.column {
  min-height: 560px;
  display: flex;
  align-items: center;
  place-content: center;
  padding: 8px 0px; }

.title {
  font-weight: bold;
  font-size: 1.2rem; }

.info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3e3e3e;
  padding: 30px;
  border-radius: 11px;
  box-shadow: 0 3px 6px rgba(1, 1, 1, 0.16), 0 3px 6px rgba(1, 1, 1, 0.23);
  background: white; }
  .info-box i {
    font-size: 36px; }

ul {
  list-style-type: none;
  padding: 0px; }

.product-preview {
  width: 100%; }

.row.center {
  justify-content: center;
  margin: 8px; }

</style>

<svelte:head>
  <link
    rel="stylesheet"
    href="https://use.fontawesome.com/releases/v5.8.0/css/all.css" />
  <link
    rel="stylesheet"
    href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
    integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
    crossorigin="anonymous" />
</svelte:head>
<ClientNavBar/>
<div class="container">
  <div class="row center">
  <h1>Dashboard</h1>
  </div>
  <div class="row">
    <div class="column col-md">

      {#await fetchSomeProducts}
        <p>...waiting</p>
      {:then { parentListing }}

      {#if parentListing[0]}
      <div class="product-preview">
          <h3>Products</h3>
          <ul>
            {#each parentListing as product}
              <CompresedProductCard product={product}/>
            {/each}
          </ul>
          {#if parentListing.length > 3}
            <a class='btn btn-primary' href='/products_new'>List all products</a>
          {/if}
      </div>

      {:else}
        <div class='info-box no-info'>
          <i class="fas fa-box-open"></i>
          <div class='message'>
            You don't have any products yet
          </div>
          
          <a class='btn btn-primary' href='/products/new_new'>
            Add a product
          </a>
        </div>
      {/if}



      {:catch error}
        <p>An error occurred!</p>
      {/await}


    </div>
    <div class="column col-md">
      {#await fetchShopInfo}
       <p>...Loading shop data</p>
      {:then { microShop }}

      {#if microShop && microShop.id}
      <div class='info-box shop-info'>
        <div class="title">
          Your shop is ready to go:
        </div>
        {#if microShop.active}
          It's active and live at <a href="/{microShop.name}">strykin.com/{microShop.name}</a>
          <p>
          So get sharing!
          </p>
        {:else}
         Your shop is currently not active; <a class='btn btn-primary' href="/microshop/update">Set it to active here</a>
        {/if}
      </div>

      {:else}
        <div class='info-box no-info'>
          <i class="fas fa-store"></i>
          <div class='message'>
            Your store is not setup
          </div>
          <a class='btn btn-primary' href='/microshop/update'>
            Setup store
          </a>
        </div>
      {/if}

      {:catch error}
        <p>An error occurred!</p>
      {/await}
    </div>
  </div>



</div>
