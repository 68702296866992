<!-- https://github.com/sveltejs/svelte-virtual-list -->

<script>
	import { onMount } from "svelte";
	import ClientNavBar from './miniComponents/ClientNavBar.svelte'
	import {getStrykinAuth} from "./utils/utils.js";

	const backendUrl = 'https://api2.strykin.com';
	let userConnectStatus = {};

	const loadListings = () => {
		fetch(backendUrl + "/private/userConnectStatus", {
		headers: {
			Authorization:  getStrykinAuth(),
			"Content-Type": "application/json"
		}
		})
		.then(response => {
			return response.json();
		})
		.then(( res ) => {
			userConnectStatus = res;

		});
	};

	const connectAccount = ()=>{
		fetch( backendUrl + "/private/onboard-user", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				'Authorization':   getStrykinAuth(),
			}
		})
		.then(response => response.json())
		.then(data => {
			if (data.url) {
				window.location = data.url;
			} else {
				console.log("err", err);
			}
		});
	}


  onMount(async function() {

	  

  });

  loadListings();
	
</script>
<link rel="stylesheet" href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css'>
<ClientNavBar/>
<main>

  <h1>Stripe connect status</h1>
  <div on:click={() => connectAccount()}>
    {userConnectStatus.active? 'refresh' :'connect' } 
  </div>
</main>

<style>
	.container {
		border-top: 1px solid #333;
		border-bottom: 1px solid #333;
		min-height: 200px;
		height: calc(100vh - 10em);
		margin-top: 5px;
	}
	.footer-page{


	}
	main {
    	margin: 8px;
  	}
</style>