<svelte:head>
	<!-- elements go here -->
    <script src="https://sdk.amazonaws.com/js/aws-sdk-2.718.0.min.js" ></script>
    <script src="../../../js/s3_photoExample.js">var albumBucketName = "strykin-client-uploads";
var bucketRegion = "eu-west-1";
var IdentityPoolId = "IDENTITY_POOL_ID";
var IdentityPoolId = "eu-west-1:81048cea-3527-442c-bd4d-81e7c4c9c14a";

AWS.config.update({
  region: bucketRegion,
  // credentials: new AWS.CognitoIdentityCredentials({
  //   IdentityPoolId: IdentityPoolId,
  //   region: bucketRegion,
  //   //RoleArn: 'arn:aws:iam::840232780447:role/Cognito_guest_image_uploadUnauth_Role',
  // })
});


AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
    region: bucketRegion,
    //RoleArn: 'arn:aws:iam::840232780447:role/Cognito_guest_image_uploadUnauth_Role',
  })
});

AWS.config.credentials.get(function(err) {
  if (err) console.log(err);
  else console.log(AWS.config.credentials);
});



var cognitoidentity = new AWS.CognitoIdentity({apiVersion: '2014-06-30'});

var params = {
  IdentityPoolId: IdentityPoolId, /* required */
  // AccountId: 'STRING_VALUE',
  // Logins: {
  //   '<IdentityProviderName>': 'STRING_VALUE',
  //   /* '<IdentityProviderName>': ... */
  // }
};
cognitoidentity.getId(params, function(err, data) {
  if (err) console.log(err, err.stack); // an error occurred
  else     console.log('getId',data);           // successful response

});

var s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: albumBucketName }
});


var params = {
  Bucket: albumBucketName /* required */,
};
s3.headBucket(params, function(err, data) {
  if (err) console.log(err, err.stack); // an error occurred
  else     console.log('headBucket',data);           // successful response
});

console.log('s3',s3);





// var params = {
//   IdentityPoolId: 'STRING_VALUE' /* required */
// };


// var cognitoUser = userPool?.getCurrentUser();

// if (cognitoUser != null) {
// 	cognitoUser.getSession(function(err, result) {
// 		if (result) {
// 			console.log('You are now logged in.');

// 			// Add the User's Id Token to the Cognito credentials login map.
// 			AWS.config.credentials = new AWS.CognitoIdentityCredentials({
// 				IdentityPoolId: IdentityPoolId,
// 				Logins: {
// 					'cognito-idp.eu-west-1.amazonaws.com/eu-west-1:81048cea-3527-442c-bd4d-81e7c4c9c14a': result.getIdToken().getJwtToken()
// 				}
// 			});
// 		}
// 	});
// }
// var cognitoidentity = new AWS.CognitoIdentity();
// // cognitoidentity.createIdentityPool(params, function (err, data) {
// //   if (err) console.log(err, err.stack); // an error occurred
// //   else     console.log(data);           // successful response
// // });


// var params = {
//   IdentityId: IdentityPoolId, /* required */

//   // CustomRoleArn: 'STRING_VALUE',
//   // Logins: {
//   //   '<IdentityProviderName>': 'STRING_VALUE',
//   //   /* '<IdentityProviderName>': ... */
//   // }
// };

// cognitoidentity.getCredentialsForIdentity(params, function(err, data) {
//   if (err) console.log(err, err.stack); // an error occurred
//   else     console.log(data);           // successful response
// });


console.log(s3);

function listAlbums() {
  s3.listObjects({ Delimiter: "/" }, function(err, data) {
    if (err) {
      return alert("There was an error listing your albums: " + err.message);
    } else {
      var albums = data.CommonPrefixes.map(function(commonPrefix) {
        var prefix = commonPrefix.Prefix;
        var albumName = decodeURIComponent(prefix.replace("/", ""));
        return getHtml([
          "<li>",
          "<span onclick=\"deleteAlbum('" + albumName + "')\">X</span>",
          "<span onclick=\"viewAlbum('" + albumName + "')\">",
          albumName,
          "</span>",
          "</li>"
        ]);
      });
      var message = albums.length
        ? getHtml([
            "<p>Click on an album name to view it.</p>",
            "<p>Click on the X to delete the album.</p>"
          ])
        : "<p>You do not have any albums. Please Create album.";
      var htmlTemplate = [
        "<h2>Albums</h2>",
        message,
        "<ul>",
        getHtml(albums),
        "</ul>",
        "<button onclick=\"createAlbum(prompt('Enter Album Name:'))\">",
        "Create New Album",
        "</button>"
      ];
      document.getElementById("app").innerHTML = getHtml(htmlTemplate);
    }
  });
}

function createAlbum(albumName) {
  albumName = albumName.trim();
  if (!albumName) {
    return alert("Album names must contain at least one non-space character.");
  }
  if (albumName.indexOf("/") !== -1) {
    return alert("Album names cannot contain slashes.");
  }
  var albumKey = encodeURIComponent(albumName);
  s3.headObject({ Key: albumKey }, function(err, data) {
    if (!err) {
      return alert("Album already exists.");
    }
    if (err.code !== "NotFound") {
      return alert("There was an error creating your album: " + err.message);
    }
    s3.putObject({ Key: albumKey }, function(err, data) {
      if (err) {
        return alert("There was an error creating your album: " + err.message);
      }
      alert("Successfully created album.");
      viewAlbum(albumName);
    });
  });
}

function viewAlbum(albumName) {
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  s3.listObjects({ Prefix: albumPhotosKey }, function(err, data) {
    if (err) {
      return alert("There was an error viewing your album: " + err.message);
    }
    // 'this' references the AWS.Response instance that represents the response
    var href = this.request.httpRequest.endpoint.href;
    var bucketUrl = href + albumBucketName + "/";

    var photos = data.Contents.map(function(photo) {
      var photoKey = photo.Key;
      var photoUrl = bucketUrl + encodeURIComponent(photoKey);
      return getHtml([
        "<span>",
        "<div>",
        '<img style="width:128px;height:128px;" src="' + photoUrl + '"/>',
        "</div>",
        "<div>",
        "<span onclick=\"deletePhoto('" +
          albumName +
          "','" +
          photoKey +
          "')\">",
        "X",
        "</span>",
        "<span>",
        photoKey.replace(albumPhotosKey, ""),
        "</span>",
        "</div>",
        "</span>"
      ]);
    });
    var message = photos.length
      ? "<p>Click on the X to delete the photo</p>"
      : "<p>You do not have any photos in this album. Please add photos.</p>";
    var htmlTemplate = [
      "<h2>",
      "Album: " + albumName,
      "</h2>",
      message,
      "<div>",
      getHtml(photos),
      "</div>",
      '<input id="photoupload" type="file" accept="image/*">',
      '<button id="addphoto" onclick="addPhoto(\'' + albumName + "')\">",
      "Add Photo",
      "</button>",
      '<button onclick="listAlbums()">',
      "Back To Albums",
      "</button>"
    ];
    document.getElementById("app").innerHTML = getHtml(htmlTemplate);
  });
}

function addPhoto(albumName) {
  var files = document.getElementById("photoupload").files;
  if (!files.length) {
    return alert("Please choose a file to upload first.");
  }
  var file = files[0];
  var fileName = file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file,
      ACL: "public-read"
    }
  });

  var promise = upload.promise();

  promise.then(
    function(data) {
      alert("Successfully uploaded photo.");
      viewAlbum(albumName);
    },
    function(err) {
      return alert("There was an error uploading your photo: ", err.message);
    }
  );
}

function addPhoto2(files,albumName,fileName,  ContentType = 'image/png') {
  //var files = document.getElementById("photoupload").files;
  if (!files.length) {
    return alert("Please choose a file to upload first.");
  }
  var file = files[0];
  //var fileName = file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    partSize: 7 * 1024 * 1024, queueSize: 1,
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
      ContentType: ContentType
    }
  })
  
  // .on('httpUploadProgress',function(progress) {
  // let newPerc = Math.round(progress.loaded / progress.total * 100);
  // if (newPerc != perc) {
  //    perc = newPerc;
  //    console.log(perc + '% sent');
  // }
  // });;

  return upload.promise();

  // promise.then(
  //   function(data) {
  //     alert("Successfully uploaded photo.");
  //     viewAlbum(albumName);
  //   },
  //   function(err) {
  //     return alert("There was an error uploading your photo: ", err.message);
  //   }
  // );
}


function addVideo2(files,albumName,fileName,  ContentType = 'image/png') {
  //var files = document.getElementById("photoupload").files;
  if (!files.length) {
    return alert("Please choose a file to upload first.");
  }
  var file = files[0];
  //var fileName = file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";

  var photoKey = albumPhotosKey + fileName;

  // Use S3 ManagedUpload class as it supports multipart uploads
  var upload = new AWS.S3.ManagedUpload({
    partSize: 7 * 1024 * 1024, queueSize: 1,
    params: {
      Bucket: albumBucketName,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
      ContentType: ContentType
    }
  })
  
  // .on('httpUploadProgress',function(progress) {
  // let newPerc = Math.round(progress.loaded / progress.total * 100);
  // if (newPerc != perc) {
  //    perc = newPerc;
  //    console.log(perc + '% sent');
  // }
  // });;

  return upload;

  // promise.then(
  //   function(data) {
  //     alert("Successfully uploaded photo.");
  //     viewAlbum(albumName);
  //   },
  //   function(err) {
  //     return alert("There was an error uploading your photo: ", err.message);
  //   }
  // );
}

function deletePhoto(albumName, photoKey) {
  s3.deleteObject({ Key: photoKey }, function(err, data) {
    if (err) {
      return alert("There was an error deleting your photo: ", err.message);
    }
    alert("Successfully deleted photo.");
    viewAlbum(albumName);
  });
}

function deleteAlbum(albumName) {
  var albumKey = encodeURIComponent(albumName) + "/";
  s3.listObjects({ Prefix: albumKey }, function(err, data) {
    if (err) {
      return alert("There was an error deleting your album: ", err.message);
    }
    var objects = data.Contents.map(function(object) {
      return { Key: object.Key };
    });
    s3.deleteObjects(
      {
        Delete: { Objects: objects, Quiet: true }
      },
      function(err, data) {
        if (err) {
          return alert("There was an error deleting your album: ", err.message);
        }
        alert("Successfully deleted album.");
        listAlbums();
      }
    );
  });
}
</script>
    
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/uppload.css'>
    <link rel='stylesheet' href='https://unpkg.com/uppload@2.3.0/dist/themes/light.css'>
</svelte:head>




<script>
const input = document.getElementById('file-input');
const video = document.getElementById('video');
const videoSource = document.createElement('source');
//let videoSrc = ''
let paused = true;
let file
let fileSize = 0
let videoWidth = 0
let videoHeight = 0

let maxFileSize = 11500000

let uploaded = false;

export let value;

export let videoPreview;


export let userId;

const preview = function() {
  const files = this.files || [];

  if (!files.length) return;
  
  const reader = new FileReader();

  reader.onload = function (e) {
    videoPreview =  e.target.result
    // paused = true
    // setTimeout(()=>{    paused = false }, 30);
    file = files[0]
    fileSize = file.size

    console.log('play', files)

  };
  
  reader.onprogress = function (e) {
    console.log('progress: ', Math.round((e.loaded * 100) / e.total));
  };
  
  reader.readAsDataURL(files[0]);
};

let uploading = false

$: fileSutible =  (((videoWidth/videoHeight) < 0.7) && (maxFileSize > fileSize) && ( fileSize > 20 ))?true:false;

$: ratioS = ((videoWidth/videoHeight) < 0.7) ?true:false;
$: fileSizeB = (maxFileSize > fileSize) ?true:false;
$: fileSizeS = ( fileSize > 20 );


let percUpload = 0;
let handleUploadClick = ()=> {
  if(uploading){
    return
  }

  uploading = true;

  percUpload = 0;
  console.log('FFILLE',file);
          addVideo2([file], userId  || 'noID',`${
          Math.random().toString().replace("0.", "").substr(0, 7)
          }-${new Date().getTime()}.mp4`,'video/mp4')
          .on('httpUploadProgress',function(progress) {
                console.log(progress);
                let newPerc = Math.round(progress.loaded / progress.total * 100);
                if (newPerc != percUpload) {
                  percUpload = newPerc;
                  console.log(percUpload + '% sent');
                }
          })
          .promise()
          .then((data)=>{
            let uploading = false
            console.log('UPLOADED',data)

            value = data.Location

            uploaded = true;
          })
          
}

let foop = (e)=>{
  console.log('videoChange',e);
}

</script>
<style>
.uppload-image{
    width:280px;
    display: flex;
}
.btn{
    margin: 5px;
    padding: 9px 19px;
    font-size: 16px;
    background: #37D2A7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px 11px;
    font-size: 13px;
    display: inline-block;
}

.loader{
  width:100%;
}

.loader-bar{
  height:5px;
  background-image: linear-gradient( 135deg, #602bb6, #dd1173);
  border-radius: 3px;
  transition: width 0.5s;

}
.loader-bar.finished{
    background: #37D2A7;
}

.loader{
  border: 1px solid darkgrey;
  border-radius: 4px;
  width: 280px;
  margin: 7px 0px;
}

#image.hiddenInput{
  display: none;
}

  video{
    display:none;
  }

  .note{
    display: inline-block;
    border: solid 0.5px #b3b3b3;
    padding: 8px 10px;
    margin: 8px 0px;
    background: #0A75CD;
    border-radius: 5px;
    color: white;
  }

  .note.warning{
    background: #fbc02d;
  }
  .note .title{
    display: block;
  }

  .btn {
    margin: 5px;
    padding: 9px 19px;
    font-size: 16px;
    background: #37D2A7;
    color: white;
    border-radius: 7px;
    border: 1px solid grey;
    padding: 5px 11px;
    font-size: 13px;
    display: inline-block;
}
@keyframes pulse {
  0% {
    background-color: #37D2A7;
  }
  100% {
    background-color: #46b597;
  }
}

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.glowing{
  animation: pulse 0.7s infinite,wiggle 2.5s infinite;

  
  animation-direction: alternate;
}


</style>
<div>
<input  on:change={preview} id="file-input" type="file" accept="video/mp4">

<video src={videoPreview} bind:videoWidth bind:videoHeight bind:paused id="video"  controls></video>

{#if uploading || uploaded }
<div>
{percUpload}%
</div>
<div class="loader">
  <div style="width:{percUpload}% " class="loader-bar {percUpload == 100 ? 'finished':''}">
  </div>
</div>
{/if}


{#if fileSize > maxFileSize}
<div>
<div class='note warning'>
 <span class='title'>Warning: </span>
file is too big max file size is: 11mb</div>
</div>
{/if}
{#if (videoWidth/videoHeight) > 0.7}
<div>

  <div class='note warning'>

    <span class='title'>Warning: </span>
    video needs to be in portrait format
  </div>
</div>
{/if}

<!-- <div>sutible: {fileSutible}</div>
<div>ratioS: {ratioS} {(videoWidth/videoHeight)}</div>
<div>fileSizeB: {fileSizeB}</div>
<div>fileSizeS: {fileSizeS} {fileSize > 20} {fileSize}</div> -->

{#if (!uploaded && fileSutible) }
<div class='btn glowing { uploading?'':'disabled'}' on:click={handleUploadClick}>
	{ uploading?'uploading':'upload video'} 
</div>

{:else if uploaded}
<div class='btn'>
	uploaded
</div>


{/if}

</div>
