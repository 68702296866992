<script>
    import ClientNavBar from "./miniComponents/ClientNavBar.svelte";
    import {getStrykinAuth} from "./utils/utils.js";

    const backendUrl = 'https://api2.strykin.com';

	let usersList = [];

    let selected;
    
    let selectedLogin;
    
    let youtubeId;

    let liveMode;




    var videoSocket = io(backendUrl+'/liveSwitchBoard', {
      transports: ['websocket', 'xhr-polling']
    });

    var liveSwitchBoard = io('{{backendUrl}}/liveSwitchBoard', {
      transports: ['websocket', 'xhr-polling']
    });



    videoSocket.on('liveSwitchBoard', function (msg) {
        if('liveSwitchBoard',msg.isLive){
            liveMode = true;
        }else{
            liveMode = false;
        }
    })

    const updateLiveSwitchObject = (updateObject)=>{
        fetch(`${backendUrl}/public/liveSwitchObject`,
        {
			method: 'POST',
			headers:{
            'Authorization':  getStrykinAuth(),
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(updateObject),
        })
        .then((response) => {
            return response.json();
        })
    }

    const toggleLiveMode = ()=>{
        updateLiveSwitchObject({
                "isLive": liveMode,
            })
    }

    const switchAspectRatio = (aspectRatio)=>{
               updateLiveSwitchObject({
                "aspectRatio": aspectRatio,
            })
    }

    function clearSpotlight(){
        fetch(`${backendUrl}/public/activeProduct/null`,
        {
			method: 'POST',
			headers:{
            'Authorization':  getStrykinAuth(),
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            }),
        })
        .then((response) => {
            return response.json();
        })
    }

	function updateCurrentUser(selectedId) {
        fetch(`${backendUrl}/public/liveSwitchClient`,
        {
			method: 'POST',
			headers:{
            'Authorization': getStrykinAuth(),
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "clientId": selectedId,
            }),
        })
        .then((response) => {
            return response.json();
        })
    }

    function updateCurrentVideo() {
        fetch(`${backendUrl}/public/changeActiveVideo`,
        {
			method: 'POST',
			headers:{
            'Authorization':  getStrykinAuth(),
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "youtubeId": youtubeId
            }),
        })
        .then((response) => {
            return response.json();
        })
    }

    const loginAs = (userId)=>{
        console.log(userId);

        fetch(`${backendUrl}/private/impersonate/${userId}`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            //mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
			headers:{
            'Authorization':  getStrykinAuth(),
            'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client

            body: JSON.stringify({}) // body data type must match "Content-Type" header
        }).then((response) => {
            return response.json()
        }).then((json) => {
            console.log(json);
            if(json.msg){
            console.log(json.msg)
            }
            if(json.token){
            sessionStorage.setItem('userType', json.user.type);
            sessionStorage.setItem('jwt', json.token);
            window.location.replace("/products_new");
            }
        }).catch((err) => {
            console.log(err);
        });

    } 

    fetch(`${backendUrl}/private/users`,
        {headers:{
            'Authorization': getStrykinAuth(),
            'Content-Type': 'application/json'
            }})
        .then((response) => {
            return response.json();
        })
        .then(({users}) => {         
            usersList = users
        }).catch(err=>{
            console.log('Maybe you are not logged in err:',err)
        })

</script>

<style>
	input { display: block; width: 500px; max-width: 100%; }
</style>
<ClientNavBar />

<h2>Admin tools</h2>

<form on:submit|preventDefault={()=>{updateCurrentUser(selected)}}>

    <label>Change active streamer:

   <input bind:value={selected}  list="ice-cream-flavors" id="ice-cream-choice" name="ice-cream-choice" />

    <datalist id="ice-cream-flavors">
		{#each usersList as user}
			<option value={user.id}>
              {user.email}
			</option>
		{/each}
    </datalist>
    </label>


	<button type=submit>
		Submit
	</button>|
</form>
<button on:click={()=>{updateCurrentUser('')}}>
    clear active
</button>



<hr>

<form on:submit|preventDefault={updateCurrentVideo}>

    <label>Change active video:

        <input bind:value={youtubeId}  />

        <p>Cheatsheet:</p>
        
            <ul>
            <li>Allblack-vid: LFVBidS-EA0</li> 
            <li>you missed the last stream: WS_a9sgX_tY</li>
            </ul>


            <button type=submit>
                Set new live vid
            </button>
    </label>
</form>

<button on:click={()=>{switchAspectRatio('landscape')}}>Set video landscape</button>
<button on:click={()=>{switchAspectRatio('portrait') }}>Set video portrait</button>

<hr>

<form on:submit|preventDefault={toggleLiveMode}>

    <label>Change live state: (Checked if Live)

    <input type=checkbox bind:checked={liveMode}>


        <button type=submit>
            Set live mode
        </button>
    </label>
</form>
<hr>
<button on:click={clearSpotlight}>
    clear spotlight
</button>

<hr>

<form on:submit|preventDefault={()=>{loginAs(selectedLogin)}}>

    <label>impersonate:

   <input bind:value={selectedLogin}  list="ice-cream-flavors" id="ice-cream-choice" name="ice-cream-choice" />

    <datalist id="ice-cream-flavors">
		{#each usersList as user}
			<option value={user.id}>
              {user.email}
			</option>
		{/each}
    </datalist>
    </label>


	<button type=submit>
		Login
	</button>|
</form>